import React from "react";
import { Auth, API } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useUserDispatch } from "../UserContext";
import { Buffer } from "buffer";

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useUserDispatch();
  const [qr, setQr] = useState("");

  const signOut = async () => {
    try {
      await Auth.signOut();
      navigate("/login");
      dispatch({
        type: "delete",
      });
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  const makeQR = async (qrUrl) => {
    const response = await API.post("test-api", "/api/qr", {
      response: true,
      body: {
        data: qrUrl,
      },
    });

    const buff = Buffer.from(response["data"], "base64");

    const blob = new Blob([buff], {
      type: "image/png",
    });

    const fileURL = window.URL.createObjectURL(blob);

    let alink = document.createElement("a");

    alink.href = fileURL;
    alink.download = `${qrUrl.replace(".", "-")}.png`;
    alink.click();
  };

  return (
    <div className="row m-2">
      <button
        type="button margin-top"
        className="btn btn-primary"
        onClick={() => signOut()}
      >
        Log Out
      </button>
      <br />
      <button
        type="button margin-top"
        className="btn btn-primary"
        onClick={() => makeQR(qr)}
      >
        Make Request
      </button>

      <input
        type="text"
        id="qr-url"
        className="form-control"
        placeholder="qr-url"
        onChange={(evt) => setQr(evt.target.value)}
      />
    </div>
  );
};

export default Dashboard;
