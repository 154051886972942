import React from "react";
import { Auth } from "aws-amplify";
import { useState } from "react";
import Background from "../static_assets/restaurant.jpg";

async function signUpCognito(username, password, email) {
  console.log(username);
  console.log(password);
  console.log(email);
  try {
    const { user } = await Auth.signUp({
      username,
      password,
      attributes: {
        email,
      },
      autoSignIn: {
        enabled: true,
      },
    });
    console.log(user);
  } catch (error) {
    console.log("error signing up:", error);
  }
}

const SignUp = () => {
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  console.log(confirmPassword);

  return (
    <div className="container-fluid">
      <div id="login-page" className="row align-items-center">
        <div
          className="col-md-6"
          style={{
            background: `url(${Background}) no-repeat`,
            backgroundSize: "cover",
            height: "100vh",
          }}
        ></div>
        <div className="col-md-6">
          <div className="row justify-content-center">
            <div className="col-4 align-self-center">
              <div className="row m-2">
                <input
                  type="text"
                  id="username"
                  className="form-control"
                  placeholder="username"
                  onChange={(evt) => setUser(evt.target.value)}
                />
              </div>
              <div className="row m-2">
                <input
                  type="text"
                  id="email"
                  className="form-control"
                  placeholder="email"
                  onChange={(evt) => setEmail(evt.target.value)}
                />
              </div>
              <div className="row m-2">
                <input
                  type="password"
                  id="password"
                  className="form-control"
                  placeholder="password"
                  onChange={(evt) => setPassword(evt.target.value)}
                />
              </div>
              <div className="row m-2">
                <input
                  type="password"
                  id="password"
                  className="form-control"
                  placeholder="confirm password"
                  onChange={(evt) => setConfirmPassword(evt.target.value)}
                />
              </div>
            </div>
          </div>

          <br />
          <div className="row justify-content-center">
            <div className="col-4">
              <div className="row m-2">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={async () => {
                    await signUpCognito(user, password, email);
                  }}
                >
                  submit
                </button>
              </div>
              <div className="row m-2">
                <a href="/login">Already a member? Login</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
