import { createContext, useContext, useReducer } from "react";

const UserContext = createContext(null);

const UserDispatchContext = createContext(null);

const initialUser = {
  cognitoUser: null,
};

export function UserProvider({ children }) {
  const [user, dispatch] = useReducer(userReducer, initialUser);

  return (
    <UserContext.Provider value={user}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserContext.Provider>
  );
}

export function useUser() {
  return useContext(UserContext);
}

export function useUserDispatch() {
  return useContext(UserDispatchContext);
}

function userReducer(user, action) {
  switch (action.type) {
    case "set": {
      let nextUserState = {
        ...user,
      };
      nextUserState["cognitoUser"] = action.user;
      return nextUserState;
    }
    case "delete": {
      let nextUserState = {
        ...user,
      };
      nextUserState["cognitoUser"] = null;
      return nextUserState;
    }
    default: {
      throw Error("Unknown action: " + action.type);
    }
  }
}
