import React from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useState } from "react";
import Background from "../static_assets/restaurant.jpg";
import { useUserDispatch } from "../UserContext";

const Login = () => {
  const [user, setUser] = useState(null);
  const [password, setPassword] = useState();
  const dispatch = useUserDispatch();

  const navigate = useNavigate();

  const signIn = async (username, password) => {
    try {
      const user = await Auth.signIn(username, password);

      if (user) {
        dispatch({
          type: "set",
          user: user,
        });
        navigate("/dashboard");
      } else {
        console.log("No user");
      }
      console.log(user);
    } catch (error) {
      console.log("Error signing in", error);
    }
  };

  return (
    <div className="container-fluid">
      <div id="login-page" className="row align-items-center">
        <div className="col-md-6">
          <div className="row justify-content-center">
            <div className="col-4 align-self-center">
              <div className="row m-2">
                <input
                  type="text"
                  id="username"
                  className="form-control"
                  placeholder="username"
                  onChange={(evt) => setUser(evt.target.value)}
                />
              </div>
              <div className="row m-2">
                <input
                  type="password"
                  id="password"
                  className="form-control"
                  placeholder="password"
                  onChange={(evt) => setPassword(evt.target.value)}
                />
              </div>
            </div>
          </div>
          <br />
          <div className="row justify-content-center">
            <div className="col-4">
              <div className="row m-2">
                <button
                  type="button margin-top"
                  className="btn btn-primary"
                  onClick={() => signIn(user, password)}
                >
                  Login
                </button>
              </div>
              <br />
              <div className="row m-2">
                <a href="/signup">Not a member yet? Sign Up</a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-md-6"
          style={{
            background: `url(${Background}) no-repeat`,
            backgroundSize: "cover",
            height: "100vh",
          }}
        ></div>
      </div>
    </div>
  );
};

export default Login;
