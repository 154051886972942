import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import User from "./components/User";
import SignUp from "./components/SignUp";
import Dashboard from "./components/Dashboard";
import GuardedRoute from "./components/GuardedRoute";
import { UserProvider } from "./UserContext";

const App = () => {
  return (
    <div className="App">
      <UserProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/user" element={<User />} />
            <Route path="/signup" element={<SignUp />} />
            <Route
              path="/dashboard"
              element={
                <GuardedRoute>
                  <Dashboard />
                </GuardedRoute>
              }
            />
            <Route path="*" element={<p>There's nothing here: 404!</p>} />
          </Routes>
        </Router>
      </UserProvider>
    </div>
  );
};

export default App;
