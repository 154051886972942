import { Auth } from "aws-amplify";

const config = {
  API: {
    endpoints: [
      {
        name: "test-api",
        endpoint: process.env.REACT_APP_ENDPOINT_URL,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
          };
        },
      },
    ],
  },
  Auth: {
    region: "us-east-1",
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
  },
};

export default config;
